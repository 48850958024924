
import { Component, Vue, Watch } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import FilterBar, { FilterType } from "@/components/widgets/common/FilterBar.vue"
import ContractSummaryCard from "@/components/consultant/ContractSummaryCard.vue"
import RecordSummaryCardGroup from "@/components/content/RecordSummaryCardGroup.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"
import ConsultantContractSummary from "@/components/consultant/ConsultantContractSummary.vue"
import ConflictOfInterestPolicy from "@/components/templates/ConflictOfInterestPolicy.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import moment from "moment"
import { Action, State } from "vuex-class"
import { Namespaces } from "@/constants"
import {
  ConsultantContractFragmentFragment,
  ConsultantContractsQuery,
  ConsultantContractsQueryQuery,
  ConsultantProfile,
  MyContractsQuery,
  MyContractsQueryQuery,
  ConsultantContractState,
  DeclineConsultantContractMutation,
  DeclineConsultantContractMutationMutation,
  AcceptConsultantContractMutation,
  AcceptConsultantContractMutationMutation,
} from "@/gql"
import { RequireProfilePayload } from "@/store/profile"
import { useConsultantContractState } from "@/hooks/useConsultantContractState"
import WizardDialog from "@/components/WizardDialog/WizardDialog.vue"
import Wizard from "@/components/Wizard/Wizard.vue"
import WizardStep from "@/components/Wizard/WizardStep.vue"
import Pagination from "@/components/widgets/common/Pagination.vue"
import useRouter from "@/router/useRouter"
import { Paginate } from "@/types"

enum TabContent {
  OPEN,
  ACCEPTED,
  REJECTED,
}

@Component({
  components: {
    AppPage,
    Loader,
    ContractSummaryCard,
    RecordSummaryCardGroup,
    DocumentCard,
    ConsultantContractSummary,
    ConflictOfInterestPolicy,
    WizardDialog,
    Wizard,
    WizardStep,
    Pagination,
  },
})
export default class ConsultantContracts extends Vue {
  filter: FilterType = {}

  tab = 0

  showAppPage = true
  errorLoading = false
  networkError = false

  openPagination = { page: 1, per: 10, length: 1 }
  openContractsCount = 0

  acceptedPagination = { page: 1, per: 10, length: 1 }
  acceptedContractsCount = 0

  rejectedPagination = { page: 1, per: 10, length: 1 }
  rejectedPageCount = 0
  rejectedContractsCount = 0

  loadingOpenContracts = false
  loadingAcceptedContracts = false
  loadingRejectedContracts = false
  loading = false

  openContracts: ConsultantContractFragmentFragment[] = []
  acceptedContracts: ConsultantContractFragmentFragment[] = []
  rejectedContracts: ConsultantContractFragmentFragment[] = []

  selectedOpenContract: ConsultantContractFragmentFragment | null = null
  selectedAcceptedContract: ConsultantContractFragmentFragment | null = null
  selectedRejectedContract: ConsultantContractFragmentFragment | null = null
  activeContract: ConsultantContractFragmentFragment | null = null

  showDeclineContractModal = false
  form = []
  rejectionReason = ""
  showUserAgreementDialog = false
  showAcceptContractDialog = false

  @State("profile", { namespace: Namespaces.Profile })
  profile!: ConsultantProfile

  @Action("requireProfile", { namespace: Namespaces.Profile })
  requireProfile!: (payload: RequireProfilePayload) => void

  gotoNext() {
    this.$refs.contractWizard && (this.$refs.contractWizard as Wizard).goToNextStep()
  }

  gotoPrev() {
    this.$refs.contractWizard && (this.$refs.contractWizard as Wizard).goToPreviousStep()
  }

  //Queries
  get queryOpenFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
    }
  }

  get queryAcceptedFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
      state: ConsultantContractState.Accepted,
    }
  }

  get queryRejectedFilter() {
    return {
      search: this.filter.search,
      createdAtDate: this.filter.createdAtDate,
      state: ConsultantContractState.Declined,
    }
  }

  get OpenQuery() {
    return this.can("core_team") ? ConsultantContractsQuery : MyContractsQuery
  }

  get AcceptedQuery() {
    return this.can("core_team") ? ConsultantContractsQuery : MyContractsQuery
  }

  get RejectedQuery() {
    return this.can("core_team") ? ConsultantContractsQuery : MyContractsQuery
  }
  getContractTitle(contract: ConsultantContractFragmentFragment) {
    return this.isCoreTeam() ? contract.consultant.name : contract.missionLifecycle.name
  }

  getContractSubTitle(contract: ConsultantContractFragmentFragment) {
    return this.isCoreTeam()
      ? contract.missionLifecycle.name
      : contract.application.missionStaffingPosition.jobTitle.name
  }

  getContractState(contractState: ConsultantContractState) {
    const { state } = useConsultantContractState(contractState)
    return state
  }

  mounted() {
    this.getOpenContract()
  }

  @Watch("tab")
  onTabChange() {
    if (this.filter && (this.filter.search || this.filter.createdAtDate)) {
      this.resetFilter()
    }

    this.fetchData()
  }

  @Watch("filter")
  onFilterChange() {
    this.debounceCall(() => {
      this.fetchData()
    })
  }

  onOpenPaginationChange(paginationForm: Paginate) {
    this.openPagination = { ...paginationForm }
  }

  @Watch("openPagination.page")
  onOpenPageChange() {
    this.getOpenContract()
  }

  @Watch("openPagination.per")
  onOpenPerChange() {
    this.paginationOpenLength()
    this.getOpenContract()
  }

  onAcceptedPaginationChange(paginationForm: Paginate) {
    this.acceptedPagination = { ...paginationForm }
  }

  @Watch("acceptedPagination.page")
  onAcceptedPageChange() {
    this.getAcceptedContract()
  }

  @Watch("acceptedPagination.per")
  onAcceptedPerChange() {
    this.paginationAcceptedLength()
    this.getAcceptedContract()
  }

  onRejectedPaginationChange(paginationForm: Paginate) {
    this.rejectedPagination = { ...paginationForm }
  }

  @Watch("rejectedPagination.page")
  onRejectedPageChange() {
    this.getRejectedContract()
  }

  @Watch("rejectedPagination.per")
  onrejectedPerChange() {
    this.paginationRejectedLength()
    this.getRejectedContract()
  }

  resetFilter() {
    this.$refs.filterBar && (this.$refs.filterBar as FilterBar).reset()
  }

  fetchData() {
    if (this.tab === TabContent.OPEN) {
      this.getOpenContract()
      this.selectFirstRejectedContract()
      return
    }
    if (this.tab === TabContent.ACCEPTED) {
      this.getAcceptedContract()
      this.selectFirstAcceptedContract()
      return
    }

    if (this.tab === TabContent.REJECTED) {
      this.getRejectedContract()
      this.selectFirstRejectedContract()
      return
    }
  }

  selectFirstOpenContract() {
    if (this.openContracts && this.openContracts.length) {
      this.selectedOpenContract = this.openContracts[0]
      this.activeContract = this.openContracts[0]
    }
  }

  selectFirstAcceptedContract() {
    if (this.acceptedContracts && this.acceptedContracts.length) {
      this.selectedAcceptedContract = this.acceptedContracts[0]
      this.activeContract = this.acceptedContracts[0]
    }
  }

  selectFirstRejectedContract() {
    if (this.rejectedContracts && this.rejectedContracts.length) {
      this.selectedRejectedContract = this.rejectedContracts[0]
      this.activeContract = this.rejectedContracts[0]
    }
  }

  get refetchQueries() {
    return [
      {
        query: this.OpenQuery,
        variables: this.queryOpenFilter,
      },
      {
        query: this.AcceptedQuery,
        variables: this.queryAcceptedFilter,
      },
      {
        query: this.RejectedQuery,
        variables: this.queryRejectedFilter,
      },
    ]
  }

  async fetch() {
    this.showAppPage = true
    this.errorLoading = false
    this.fetchData()
  }

  async getOpenContract() {
    this.loadingOpenContracts = true
    try {
      if (!this.can("core_team")) {
        const result = await this.$apollo.query<MyContractsQueryQuery>({
          query: MyContractsQuery,
          variables: {
            paginate: {
              page: this.openPagination.page,
              per: this.openPagination.per,
            },
            filter: this.queryOpenFilter,
          },
        })

        if (result.data && result.data.consultantProfile?.consultantContracts) {
          this.openContracts = result.data.consultantProfile.consultantContracts.data
          this.openContractsCount =
            result.data.consultantProfile.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      } else {
        const result = await this.$apollo.query<ConsultantContractsQueryQuery>({
          query: ConsultantContractsQuery,
          variables: {
            paginate: {
              page: this.openPagination.page,
              per: this.openPagination.per,
            },
            filter: this.queryOpenFilter,
          },
        })
        if (result.data && result.data.consultantContracts) {
          this.openContracts = result.data.consultantContracts.data
          this.openContractsCount = result.data.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingOpenContracts = false
      this.selectFirstOpenContract()
    }
  }

  @Watch("openContractsCount")
  paginationOpenLength() {
    this.openPagination.length =
      Math.round(this.openContractsCount / this.openPagination.per) > 0
        ? Math.round(this.openContractsCount / this.openPagination.per)
        : 1
  }

  async getAcceptedContract() {
    try {
      this.loadingAcceptedContracts = true
      if (!this.can("core_team")) {
        const result = await this.$apollo.query<MyContractsQueryQuery>({
          query: MyContractsQuery,
          variables: {
            paginate: { page: this.acceptedPagination.page, per: this.acceptedPagination.per },
            filter: this.queryAcceptedFilter,
          },
        })

        if (result.data && result.data.consultantProfile?.consultantContracts) {
          this.acceptedContracts = result.data.consultantProfile.consultantContracts.data
          this.acceptedContractsCount =
            result.data.consultantProfile.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      } else {
        const result = await this.$apollo.query<ConsultantContractsQueryQuery>({
          query: ConsultantContractsQuery,
          variables: {
            paginate: { page: this.acceptedPagination.page, per: this.acceptedPagination.per },
            filter: this.queryAcceptedFilter,
          },
        })

        if (result.data && result.data.consultantContracts) {
          this.acceptedContracts = result.data.consultantContracts.data
          this.acceptedContractsCount = result.data.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingAcceptedContracts = false
      this.selectFirstAcceptedContract()
    }
  }

  @Watch("acceptedContractsCount")
  paginationAcceptedLength() {
    this.acceptedPagination.length =
      Math.round(this.acceptedContractsCount / this.acceptedPagination.per) > 0
        ? Math.round(this.acceptedContractsCount / this.acceptedPagination.per)
        : 1
  }

  async getRejectedContract() {
    try {
      this.loadingRejectedContracts = true
      if (!this.can("core_team")) {
        const result = await this.$apollo.query<MyContractsQueryQuery>({
          query: MyContractsQuery,
          variables: {
            paginate: {
              page: this.rejectedPagination.page,
              per: this.rejectedPagination.per,
            },
            filter: this.queryRejectedFilter,
          },
        })

        if (result.data && result.data.consultantProfile?.consultantContracts) {
          this.rejectedContracts = result.data.consultantProfile.consultantContracts.data
          this.rejectedContractsCount =
            result.data.consultantProfile.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      } else {
        const result = await this.$apollo.query<ConsultantContractsQueryQuery>({
          query: ConsultantContractsQuery,
          variables: {
            paginate: {
              page: this.rejectedPagination.page,
              per: this.rejectedPagination.per,
            },
            filter: this.queryRejectedFilter,
          },
        })
        if (result.data && result.data.consultantContracts) {
          this.rejectedContracts = result.data.consultantContracts.data
          this.rejectedContractsCount = result.data.consultantContracts.pagination.totalSize
        }

        if (result && result.errors) {
          this.showAppPage = false
          this.errorLoading = true
        }
      }
    } catch (error) {
      this.showAppPage = false
      this.networkError = true
    } finally {
      this.loadingRejectedContracts = false
      this.selectFirstRejectedContract()
    }
  }

  @Watch("rejectedContractsCount")
  paginationRejectedLength() {
    this.rejectedPagination.length =
      Math.round(this.rejectedContractsCount / this.rejectedPagination.per) > 0
        ? Math.round(this.rejectedContractsCount / this.rejectedPagination.per)
        : 1
  }

  async onDeclineContract() {
    this.loading = true

    const result = await this.mutate<DeclineConsultantContractMutationMutation>({
      mutation: DeclineConsultantContractMutation,
      variables: {
        id: this.activeContract!.id,
        declineReason: this.rejectionReason,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
        this.getOpenContract()
        this.getAcceptedContract()
        this.getRejectedContract()
      },
    })

    if (result.data && !result.data.declineConsultantContract.error) {
      this.addSuccess("Offer declined successfully")
      this.showDeclineContractModal = false
    }
  }

  async onAcceptTermsAndConditions() {
    this.loading = true

    const result = await this.mutate<AcceptConsultantContractMutationMutation>({
      mutation: AcceptConsultantContractMutation,
      variables: {
        id: this.activeContract!.prn,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
        this.getOpenContract()
        this.getAcceptedContract()
        this.getRejectedContract()
      },
    })

    if (result.data && !result.data.acceptConsultantContract.error) {
      this.showAcceptContractDialog = false
      this.addSuccess("Offer accepted successfully")
    }
  }

  //functions
  setOpenContract(contract: ConsultantContractFragmentFragment) {
    const { router, routes } = useRouter()

    if (this.can("core_team")) {
      router.push({
        name: routes.ConsultantContractAdmin,
        params: {
          id: contract.id,
        },
      })
      return
    }

    this.selectedOpenContract = contract
    this.activeContract = contract
  }

  setActiveAcceptedContract(contract: ConsultantContractFragmentFragment) {
    this.selectedAcceptedContract = contract
    this.activeContract = contract
  }

  setActiveRejectedContract(contract: ConsultantContractFragmentFragment) {
    this.selectedRejectedContract = contract
    this.activeContract = contract
  }

  timeAgo(date: Date | string) {
    return moment(date).fromNow()
  }

  getContractSummaryIcons(contractSummary: any, publishedAt: string) {
    return [
      {
        name: moment(publishedAt).format("Do MMMM, YYYY"),
        icon: "la-calendar",
      },
      {
        name: contractSummary?.practiceGroup?.name,
        icon: "la-briefcase",
      },
    ]
  }
}
